import React, { useContext } from "react";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { CLOSE_CART } from "../reducers/CartReducer";
import { Link } from "react-router-dom";
import { getBitmap, getPriceString, getEncodedProductStyle, getEncodedProductColor } from "../helpers";

const CartProduct = ({ productID, size }) => {
  const { eventID, products, customUnits } = useContext(EventContext);
  const { cartDispatch } = useContext(CartContext);
  const product = products[productID];
  const productStyle = getEncodedProductStyle(product);
  const productColor = getEncodedProductColor(product);
  const productPath = product
    ? `/${eventID}/store/product/${productStyle}/${productColor}`
    : `/${eventID}`;

  if (!product) return null;

  return (
    <div className="cartProduct">
      <Link
        onClick={() => cartDispatch({ type: CLOSE_CART })}
        to={productPath}
        className="storefrontProduct"
      >
        <img 
          alt="product"
          src={getBitmap(product.image)} 
          className="productImage skeleton" 
        />
      </Link>
      <div className="productDetails">
        <span className="productName">{product.styleNameLong}</span>
        <span className="productSize">Size: {size}</span>
        <span className="productPrice">{getPriceString(product.price, customUnits)}</span>
      </div>
    </div>
  );
};

export default CartProduct;
