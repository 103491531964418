import { firestore, functions } from "./firebase";
import { createUserWithEmailAndPassword } from "./auth";
import { getNzForSizeInSizeScale, sendConfirmationEmail } from "./helpers";
import { CLIENT, USE_MASTER_LIST } from "./constants";

const usersRef = firestore.collection("users");
const cartsRef = firestore.collection("carts");
const eventsRef = firestore.collection("events");
const productListsRef = firestore.collection("event-product-lists");
const eventProductsRef = firestore.collection("event-products");
const customersRef = firestore.collection("customers");

const createCartForUser = functions.httpsCallable("httpsOnCall-createCartForUser");
const createMicrositesOrder = functions.httpsCallable("httpsOnCall-createMicrositesOrder");
export const saveUserAddress = functions.httpsCallable("httpsOnCall-saveUserAddress");
export const addEventIDToUserDocument = functions.httpsCallable("httpsOnCall-addEventIDToUserDocument");

// Find whether an event ID exists in the events collection
export const eventExists = async (eventID) => {
  const event = await eventsRef
    .where("client", "==", CLIENT)
    .where("eventID", "==", eventID).get();
  return !event.empty;
};

// Get firestore event data from an event ID
export const getEventData = async (eventID) => {
  try {
    const event = await eventsRef
      .where("client", "==", CLIENT)
      .where("eventID", "==", eventID).limit(1).get();
    return event.docs[0];
  } catch (err) {
    console.log(`Error fetching event data: ${err}`);
  }
};

// Get the embroidery data from a position code
export const getEmbroideryFromCode = async (code) => {
  if (!code) return;
  const snap = await firestore.collection("codes")
    .where("client", "==", CLIENT)
    .where("type", "==", "EL")
    .where("code", "==", code)
    .limit(1).get();
  if (snap.empty) return;
  return snap.docs[0].data();
}

// Fetch products from Firestore
export const getProducts = async (listID) => {
  console.log("Fetching products");
  // Get the list of product IDs
  try {
    const listRef = productListsRef.doc(listID);

    const productsSnapshot = await listRef.get();
    if (!productsSnapshot.exists) {
      console.log("Product list doesn't exist");
    }
    const productsArray = productsSnapshot.data().products;
    const ids = productsArray.map(p => p.xID);

    // TODO could make this async
    // Get product data for each product ID
    const promises = ids.map((id) =>
      firestore
      .collection("products")
      .where("client", "==", CLIENT)
      .where("xID", "==", id)
      .limit(1)
      .get()
    );
    const queries = await Promise.all(promises);

    // And add extra information to the existing products
    const allProducts = await Promise.all(queries.map(async(query, index) => {
      const exists = query.docs[0] && query.docs[0].exists;
      if (!exists) return;
      const product = productsArray[index];
      const productData = query.docs[0].data();
      const docID = query.docs[0].id;

      const price = !!product.eventPrice
        ? parseFloat(product.eventPrice).toFixed(2)
        : 0;

      return {
        ...productData,
        category: productData.group1 ? productData.group1 : productData.prodType,
        docID,
        wholesalePrice: parseFloat(productData.price).toFixed(2),
        price,
        embroidery: product.embroidery,
      }
    }))

    // Remove non-existing products
    const existingProducts = allProducts
      .filter(p => !!p)

    // Sort the products
    const sortedProducts = existingProducts.sort((a, b) => {
      switch (true) {
        case a.genderCode < b.genderCode:
          return -1;
        case a.genderCode > b.genderCode:
          return 1;
        case a.group1 < b.group1:
          return -1;
        case a.group1 > b.group1:
          return 1;
        case a.styleCode < b.styleCode:
          return -1;
        case a.styleCode > b.styleCode:
          return 1;
        case a.colorCode < b.colorCode:
          return -1;
        case a.colorCode > b.colorCode:
          return 1;
        default:
          return 0;
      }
    });

    // Map product IDs to the products
    const idsToProducts = Object.fromEntries(sortedProducts.map(p => {
      return [p.xID, p];
    }));

    return idsToProducts;
  } catch (error) {
    console.log(`Error fetching products: ${error}`);
  }
};

// Fetch the cart product if it exists
export const getCartProduct = async (product, size, embroidery, cart) => {
  const cartProductsRef = cartsRef.doc(cart).collection("cart-products");
  const cartProductQuery = await cartProductsRef
    .where("product", "==", product.xID)
    .where("size", "==", size)
    .where("embroidery", "==", embroidery)
    .get();
  // TODO if more than 1, merge them
  return cartProductQuery.empty
    ? {
        id: cartProductsRef.doc().id,
        quantity: 0,
      }
    : {
        id: cartProductQuery.docs[0].id,
        quantity: cartProductQuery.docs[0].data().quantity,
      };
};

// Removes a product from the cart product of a cart
export const removeProductFromCart = async (product, cart) => {
  const cartRef = cartsRef.doc(cart.cartID);
  const cartProductsRef = cartRef.collection("cart-products");
  cartProductsRef.doc(product).delete();
};

export const updateCart = async (cart) => {
  const cartRef = cartsRef.doc(cart.cartID);
  // Update the cart document
  await cartRef.update({
    budget: cart.budget,
    balance: cart.balance,
    total: cart.total,
    payPalApplied: cart.payPalApplied,
  });

  // Update the cart products
  for (const [id, cartProduct] of Object.entries(cart.products)) {
    const {product, quantity, size, embroidery} = cartProduct;
    const cartProductsRef = cartRef.collection("cart-products");

    // Set the cart product
    await cartProductsRef.doc(id).set({
      product,
      size,
      quantity,
      embroidery,
    });
  };
};

export const getCustomer = async (number) => {
  console.log("Getting customer");
  try {
    const snap = await customersRef.doc(number).get();
    return snap.data();
  } catch (error) {
    console.log(`Error fetching customer: ${error}`);
  }
}

const getAvailabilityFromMasterList = async (product, size) => {
  try {
    // Get the master product list
    const snap = await eventProductsRef.where("client", "==", CLIENT.toUpperCase()).get();
    // Get the products
    const productsRef = snap.docs[0].ref.collection("products");
    // Find this product by its xID
    const productSnap = await productsRef.where("xID", "==", product.xID).get();
    // Return this size availability
    const doc = productSnap.docs[0];
    // If this product no longer exists on the event list, it is unavailable
    if (!doc) return 0;
    const availability = doc.data().availability;
    return availability[size];
  } catch(err) {
    console.log(`Error fetching availability: ${err}`);
    return 0;
  }
}

// TODO we should fetch all availabilities first then assign them
// Waste of time to fetch them for each size
const getAvailabilityFromProductFile = (product, size) => {
  // The product file references size numbers by xavz instead of nz
  const xavz = size.replace('n', 'xav');
  if (!product.availability) return 0;

  // Initilizer object for the reduce function
  const [firstDate, firstAvailabaility] = Object.entries(product.availability)[0];

  // Calculate the earliest availability to treat as on-hand
  const earliestAvailability = Object.entries(product.availability)
    .reduce((earliest, [currentDateString, currentAvailability]) => {

    const earliestDateString = earliest.date;

    // Dates in JS are in YYYY-MM-DD but VRLINK uses YY-MM-DD
    const earliestDate = Date.parse("20" + earliestDateString);
    const currentDate = Date.parse("20" + currentDateString);

    // On-hand availability is stored as "- -", which is NaN
    const earlier = isNaN(earliestDate)
    || (earliestDate > currentDate || !isNaN(currentDate))

    return {
      date: earlier ? earliest.date : currentDateString,
      availability: earlier ? earliest.availability : currentAvailability,
    };
  }, { date:firstDate, availability: firstAvailabaility});

  const onHandAvailability = earliestAvailability.availability;
  if (!onHandAvailability) return 0;

  return onHandAvailability[xavz]
    ? parseInt(onHandAvailability[xavz])
    : 0;
}

// Get the availability of a product in a certain size within a product list
export const getAvailability = async (product, size) => {
  return USE_MASTER_LIST
    ? getAvailabilityFromMasterList(product, size)
    : getAvailabilityFromProductFile(product, size);
}

// If the event has a participant list, return the participant
// or throw an error if the email is not in the list.
// Else, create a participant and return it
const getParticipantFromList = (email, participants, password) => {
  // Create a participant if there is no participant list
  if (!participants) return { email };
  // Check that the user is a participant with access to this event
  const participant = participants?.find(p =>
    // Case-insensitive comparison
    p.email && p.email?.localeCompare(email.trim(), undefined, {sensitivity: "accent"}) === 0);
  if (!participant) {
      throw new Error("Sorry, you don't have access to this event!");
  }
  return participant;
};

// Signs into an event that has a single site-wide password
export const signInToEventWithPassword = async (email, password, eventID) => {
  email = email.trim().toLowerCase();
  // Get the event doc
  const event = await eventsRef.where("eventID", "==", eventID).limit(1).get();
  const eventDoc = event.docs[0];
  const eventData = eventDoc.data();
  // Check the site password was entered correctly
  if (password !== eventData.sitePassword) {
    throw new Error("The site password you entered does not match. Please get the password from the event manager.");
  }
  // Get the participant
  const participant = getParticipantFromList(email, eventData.participants, password);
  // Get the user doc
  const query = await usersRef
    .where("email", "==", participant.email.toLowerCase()).limit(1).get();
  // Create the user if they don't exist
  const userDoc = query.empty
  ? await createUserWithEmailAndPassword(email, password)
  : query.docs[0];
  addEventIDToUserDocument(userDoc.id, eventDoc.id);
  const user = {
    uid: userDoc.id,
    email: participant.email.toLowerCase(),
    firstName: participant.firstName,
    lastName: participant.lastName,
  }
  return user;
}

// Returns whether the user has access to the event
export const hasEventAccess = async (email, eventID) => {
  try {
    const event = await eventsRef.where("eventID", "==", eventID).limit(1).get();
    const eventDoc = event.docs[0];
    if (!eventDoc || !eventDoc.exists) {
      console.log("Event does not exist");
      return;
    }
    const participants = eventDoc.data().participants;
    // If there's no participant list, anyone can sign in with a password
    if (!participants) return true;
    return participants.some(p => {
      return !!p && p.email && p.email.localeCompare(email, undefined, {sensitivity: "accent"}) === 0;
    });
  } catch (err) {
    console.log(`Error checking event access: ${err}`);
    return false
  }
}

// Fetch style name of a product from Firestore
export const getStyleName = async (productID) => {
  console.log("Fetching style name");
  try {
    // Get the product doc
    const product = await firestore.collection("products").doc(productID).get();
    const styleName = product.data().styleNameLong;
    return styleName;
  } catch (error) {
    console.log(`Error fetching style name: ${error}`);
  }
};

// Fetch style code of a product from Firestore
export const getStyleCode = async (productID) => {
  console.log("Fetching style code");
  try {
    // Get the product doc
    const product = await firestore.collection("products").doc(productID).get();
    const styleCode = product.data().styleCode;
    return styleCode;
  } catch (error) {
    console.log(`Error fetching style code: ${error}`);
  }
};

// Fetch size scale data of a product from Firestore
export const getSizeScale = async (xID) => {
  console.log("Fetching size scale");
  try {
    // Get the size scale ID from the product document
    const sizeScaleID = await new Promise((resolve, reject) => {
      firestore
        .collection("products")
        .where("xID", "==", xID)
        .limit(1)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const sizeScaleID = doc.data().sizeScaleID;
            resolve(sizeScaleID);
          });
        });
    });

    // Get the size scale document from its ID
    return new Promise((resolve, reject) => {
      firestore
        .collection("size-scales")
        .where("client", "==", CLIENT)
        .where("id", "==", sizeScaleID)
        .limit(1)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const sizes = doc.data().sizes;
            resolve(sizes);
          });
        });
    });
  } catch (error) {
    console.log(`Error fetching size scale: ${error}`);
  }
};

// Get the individual budget for a participant in an event
export const getParticipantBudget = async (email, eventDocID) => {
  const eventSnap = await eventsRef.doc(eventDocID).get();
  const eventData = eventSnap.data();
  const participants = eventData.participants;
  const eventBudget = eventData.budget  > 0 ? eventData.budget : 0;
  if (!participants) return eventBudget
  const participant = participants.find(p => p.email.toLowerCase() === email.toLowerCase());
  return !!participant.budget
    ? parseFloat(participant.budget)
    : eventBudget;
}

// Fetch cart data from Firestore
export const getCart = async (user, budget, eventDocID) => {
  const userID = user.uid;
  console.log("Fetching user's cart");
  try {
    const cartSnap = await cartsRef.where("user", "==", userID)
      .where("event", "==", eventDocID).get();
    if (cartSnap.empty) {
      const participantBudget = await getParticipantBudget(user.email, eventDocID)
      const result = await createCartForUser({
        client: CLIENT,
        user: userID,
        budget: participantBudget,
        eventID: eventDocID});
      return result.data;
    } else {
      const cart = cartSnap.docs[0].ref;
      const cartProductsSnapshot = await cart
        .collection("cart-products")
        .get();

      // Map doc IDs to doc data
      const cartProducts = Object.fromEntries(cartProductsSnapshot.docs.map((d, i) => [d.id, d.data()]));


      return {
          ...cartSnap.docs[0].data(),
          cartID: cartSnap.docs[0].id,
          cartProducts,
        };
    }
  } catch (error) {
    console.log(`Error fetching cart: ${error}`);
  }
};

// Returns a mapping of sizes to availability if the size was once available
export const fetchAvailabilities = async (product, sizes, sizeScale) => {
  return await sizes.reduce(async (previousPromise, size) => {
    // Wait for each async promise to resolve before updating the object
    let result = await previousPromise;

    const nz = getNzForSizeInSizeScale(size, sizeScale);
    const availability = await getAvailability(product, nz);
    result[size] = availability;
    return result;
  }, Promise.resolve({}));
}

// Submit a user's order to firestore
export const submitOrderToFirestore = async (allProducts, cartID, eventData, user) => {
  // Get the user's cart data
  const cartRef = cartsRef.doc(cartID);
  const cart = await cartRef.get();
  const cartData = cart.data();
  // Get the cart products
  const cartProductsSnap = await cartRef.collection("cart-products").get()
  // Add the product price in case price changes in the future
  const cartProducts = cartProductsSnap.docs.map(p => {
    const data = p.data();
    const xID = data.product;
    const product = allProducts[xID];
    // Make sure embroidery options are selected
    if (data.embroidery) {
      for (const embroidery of data.embroidery) {
        if (!embroidery.position) throw new Error(`Please select an embroidery position for ${product.styleNameLong}`)
        if (!embroidery.tape) throw new Error(`Please select an embroidery tape for ${product.styleNameLong}`)
        if (embroidery.monogram) {
          if (!embroidery.monogram.topText) throw new Error(`Please complete the monogram config for ${product.styleNameLong}`)
          if (!embroidery.monogram.bottomText) throw new Error(`Please complete the monogram config for ${product.styleNameLong}`)
          if (!embroidery.monogram.style) throw new Error(`Please complete the monogram config for ${product.styleNameLong}`)
        }
      }
    }
    return {
      ...data,
      price: parseFloat(product.price),
    };
  });
  // Double check availability
  for (const cartProduct of cartProducts) {
    const sizeScale = await getSizeScale(cartProduct.product);
    const nz = getNzForSizeInSizeScale(cartProduct.size, sizeScale);
    const product = allProducts[cartProduct.product];
    const availability = await getAvailability(product, nz);
    if (availability < cartProduct.quantity) {
      const productName = product.styleNameLong;
      throw new Error(`Sorry, we don't have enough of ${productName} left in size ${cartProduct.size}. Please choose a smaller quantity or remove the item from your cart.`);
    }
  }
  // Create an order with the cart data
  const {data: orderID} = await createMicrositesOrder({
    ...cartData,
    client: CLIENT,
    products: cartProducts,
    promiseDate: eventData.promiseDate,
    poNumberPrefix: eventData.poNumberPrefix,
    event: eventData.eventDocID,
    customUnits: eventData.customUnits,
    user: user.uid,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    dropShip: !!eventData.dropShip,
    dropShipCharge: eventData.dropShipCharge,
    dropShipAddress: !!eventData.dropShip ? eventData.dropShipAddress : null,
    submitted: false,
  });
  if (!orderID) {
    throw new Error("There was a problem submitting your order. Please contact an administrator.");
  }
  // Don't add the order to the user doc because there's no real auth anymore
  // Add the order to the user's orders
  // await firestore
  //   .collection("users")
  //   .doc(user.uid)
  //   .update({
  //     orders: arrayUnion(orderRef.id),
  //   });
  // Clear the user's cart
  // Deduct the drop ship charge if the order was drop shipped
  const newBalance = eventData.dropShipAddress ?
    cartData.balance - eventData.dropShipCharge : cartData.balance;
  const newCart = {
    budget: newBalance < 0 ? 0 : newBalance,
    balance: newBalance < 0 ? 0 : newBalance,
    total: 0,
    user: user.uid,
    event: eventData.eventDocID,
  };
  // Decrement cart products quantities from master list
  if (USE_MASTER_LIST) decrementProducts(cartProducts);
  // Reset the cart
  await cartRef.set(newCart);
  cartProductsSnap.forEach(doc => doc.ref.delete());
  // Send the user an email
  sendConfirmationEmail(orderID);
  // Return the empty cart
  return {...newCart, products: {}, cartID, payPalApplied: 0};
};

const decrementProducts = async (products) => {
  const snap = await firestore.collection("event-products").where("client", "==", CLIENT.toUpperCase()).get();
  const productsRef = snap.docs[0].ref.collection("products");
  for (const product of products) {
    const productSnap = await productsRef.where("xID", "==", product.product).get();
    const productDoc = productSnap.docs[0];
    const availability = productDoc.data().availability;
    const sizeScale = await getSizeScale(product.product)
    const nz = getNzForSizeInSizeScale(product.size, sizeScale);
    const newAvailability = {
      ...availability,
      [nz]: availability[nz] - product.quantity,
    };
    productDoc.ref.update({availability: newAvailability});
  }
};
